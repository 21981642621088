import { render, staticRenderFns } from "./splash.vue?vue&type=template&id=754b7066&scoped=true&"
import script from "./splash.vue?vue&type=script&lang=js&"
export * from "./splash.vue?vue&type=script&lang=js&"
import style0 from "./splash.vue?vue&type=style&index=0&id=754b7066&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754b7066",
  null
  
)

export default component.exports