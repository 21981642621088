var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticStyle:{"z-index":"10"}},[_c('b-col',{attrs:{"cols":"10"}},[(_vm.workflow.config.name !== undefined)?_c('h5',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:_vm.profile.darkMode ? 'text-white' : 'text-dark',staticStyle:{"text-decoration":"underline"},attrs:{"disabled":!_vm.linkable,"variant":"outline-dark","to":{
                        name: 'workflow',
                        params: {
                            owner: _vm.workflow['repo']['owner']['login'],
                            name: _vm.workflow['repo']['name'],
                            branch: _vm.workflow['branch']['name'],
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.workflow.config.name)+" ")])],1):_c('h5',[_c('b-link',{staticClass:"text-danger",attrs:{"to":{
                        name: 'workflow',
                        params: {
                            owner: _vm.workflow['repo']['owner']['login'],
                            name: _vm.workflow['repo']['name'],
                            branch: _vm.workflow['branch']['name'],
                        },
                    }}},[_c('i',{staticClass:"fas fa-exclamation-circle text-danger mr-2"}),_c('small',[_vm._v("(name not provided)")])])],1),(
                    _vm.workflow.repo.topics !== undefined &&
                    _vm.workflow.repo.topics.length > 0
                )?_c('div',[_vm._l((_vm.workflow.repo.topics),function(topic){return _c('b-badge',{key:topic,staticClass:"mr-1 mb-0",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(topic))])}),_c('br')],2):_vm._e(),_c('small',[_c('b-link',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark',on:{"click":function($event){return _vm.openInNewTab(
                            'https://github.com/' +
                                _vm.workflow.repo.owner.login +
                                '/' +
                                _vm.workflow.repo.name
                        )}}},[_c('i',{staticClass:"fab fa-github fa-fw"}),_vm._v(" "+_vm._s(_vm.workflow.repo.owner.login)+"/"+_vm._s(_vm.workflow.repo.name)+" ")])],1),_c('br'),_c('small',[_c('i',{staticClass:"fas fa-code-branch fa-fw mr-1"}),_vm._v(_vm._s(_vm.workflow.branch.name)+" ")]),_c('br'),_c('small',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark'},[_vm._v(_vm._s(_vm.workflow.repo.description))])]),_c('b-col',{attrs:{"cols":"1"}})],1),(_vm.workflow.config.logo !== undefined)?_c('b-img',{staticClass:"card-img-right",staticStyle:{"max-width":"6rem","position":"absolute","right":"-15px","top":"-25px","z-index":"1"},attrs:{"rounded":"","right":"","src":`https://raw.githubusercontent.com/${_vm.workflow.repo.owner.login}/${_vm.workflow.repo.name}/master/${_vm.workflow.config.logo}`}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }