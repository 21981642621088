<template>
    <div class="m-0 p-5">
        <br />
        <br />
        <b-container>
            <b-row align-content="center">
                <b-col>
                    <h5 class="text-center">this page does not exist</h5>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'notfound',
};
</script>
