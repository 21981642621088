var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br'),_c('br'),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h4',{class:_vm.profile.darkMode ? 'text-white' : 'text-theme',staticStyle:{"text-decoration":"underline","z-index":"100"}},[_vm._v(" plant"),_c('small',{staticClass:"mb-3 text-success",staticStyle:{"text-decoration":"underline","text-shadow":"1px 1px 2px black","z-index":"100"}},[_c('small',[_vm._v("IT")])]),_c('small',[_vm._v("stats")])])])],1),_c('b-row',[_c('b-col',{staticClass:"text-right mr-0",attrs:{"align-self":"end"}},[_c('b-tabs',{attrs:{"nav-class":"bg-transparent","active-nav-item-class":"bg-transparent text-dark","pills":"","align":"center"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab',{class:_vm.profile.darkMode
                                ? 'theme-dark m-0 p-3'
                                : 'theme-light m-0 p-3',attrs:{"title":"Workflows","title-link-class":_vm.profile.darkMode ? 'text-white' : 'text-dark'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',{staticClass:"text-success text-center"},[(_vm.workflowCount >= 0)?_c('span',[_vm._v(_vm._s(_vm.workflowCount))]):_c('b-spinner',{attrs:{"label":"Loading...","variant":"secondary"}})],1),_c('b-button',{attrs:{"variant":_vm.activeTab === 0
                                        ? _vm.profile.darkMode
                                            ? 'outline-success'
                                            : 'success'
                                        : _vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white'}},[_c('br'),_vm._v(" phenomics "),_c('br'),_vm._v(" workflows")])]},proxy:true}])}),_c('b-tab',{class:_vm.profile.darkMode
                                ? 'theme-dark m-0 p-3'
                                : 'theme-light m-0 p-3',attrs:{"title-link-class":_vm.profile.darkMode ? 'text-white' : 'text-dark'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',{staticClass:"text-success text-center"},[(_vm.userCount >= 0)?_c('span',[_vm._v(_vm._s(_vm.userCount))]):_c('b-spinner',{attrs:{"label":"Loading...","variant":"secondary"}})],1),_c('b-button',{attrs:{"variant":_vm.activeTab === 1
                                        ? _vm.profile.darkMode
                                            ? 'outline-success'
                                            : 'success'
                                        : _vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white'}},[_c('br'),_vm._v(" scientists &"),_c('br'),_vm._v("researchers")])]},proxy:true}])}),_c('b-tab',{class:_vm.profile.darkMode
                                ? 'theme-dark m-0 p-3'
                                : 'theme-light m-0 p-3',attrs:{"title":"Developers","title-link-class":_vm.profile.darkMode ? 'text-white' : 'text-dark'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',{staticClass:"text-success text-center"},[(_vm.developerCount >= 0)?_c('span',[_vm._v(_vm._s(_vm.developerCount))]):_c('b-spinner',{attrs:{"label":"Loading...","variant":"secondary"}})],1),_c('b-button',{attrs:{"variant":_vm.activeTab === 2
                                        ? _vm.profile.darkMode
                                            ? 'outline-success'
                                            : 'success'
                                        : _vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white'}},[_c('br'),_vm._v(" workflow "),_c('br'),_vm._v(" developers")])]},proxy:true}])},[_c('b-card-group',_vm._l((_vm.developers),function(user){return _c('b-card',{key:user,staticClass:"overflow-hidden text-left p-2",attrs:{"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"border-variant":"default","header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"text-variant":_vm.profile.darkMode ? 'white' : 'dark'}},[_c('b-row',[_c('b-col',[_c('b-link',{class:_vm.profile.darkMode
                                                    ? 'text-light'
                                                    : 'text-dark',attrs:{"href":`https://github.com/${user}`}},[_c('i',{staticClass:"fab fa-github fa-fw"}),_vm._v(" "+_vm._s(user))])],1),_c('b-col',{attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.workflows.filter( (wf) => wf.repo.owner.login === user ).length))])],1)],1)}),1)],1),_c('b-tab',{class:_vm.profile.darkMode
                                ? 'theme-dark m-0 p-3'
                                : 'theme-light m-0 p-3',attrs:{"title":"Tasks","title-link-class":_vm.profile.darkMode ? 'text-white' : 'text-dark'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',{staticClass:"text-success text-center"},[(_vm.taskCount >= 0)?_c('span',[_vm._v(_vm._s(_vm.taskCount))]):_c('b-spinner',{attrs:{"label":"Loading...","variant":"secondary"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":_vm.activeTab === 2
                                        ? _vm.profile.darkMode
                                            ? 'outline-success'
                                            : 'success'
                                        : _vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white',"title":`Tasks`}},[_c('br'),_vm._v(" workflow "),_c('br'),_vm._v(" submissions ")])]},proxy:true}])}),_c('b-tab',{class:_vm.profile.darkMode
                                ? 'theme-dark m-0 p-3'
                                : 'theme-light m-0 p-3',attrs:{"title":"Institutions","title-link-class":_vm.profile.darkMode ? 'text-white' : 'text-dark'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',{staticClass:"text-success text-center"},[(_vm.institutionCount >= 0)?_c('span',[_vm._v(_vm._s(_vm.institutionCount))]):_c('b-spinner',{attrs:{"label":"Loading...","variant":"secondary"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":_vm.activeTab === 3
                                        ? _vm.profile.darkMode
                                            ? 'outline-success'
                                            : 'success'
                                        : _vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white',"title":"Institutions"}},[_c('br'),_vm._v(" represented "),_c('br'),_vm._v(" institutions ")])]},proxy:true}])})],1)],1)],1),_c('div',{staticStyle:{"height":"40rem","width":"100%"},attrs:{"id":"map"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }