var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group',{staticClass:"m-0",attrs:{"flush":""}},[(_vm.showSharedAlert)?_c('b-row',[_c('b-col',{staticClass:"m-0 p-0"},[_c('b-alert',{attrs:{"show":_vm.showSharedAlert,"variant":_vm.sharedAlertMessage.startsWith('Failed')
                        ? 'danger'
                        : 'success',"dismissible":""},on:{"dismissed":function($event){_vm.showSharedAlert = false}}},[_vm._v(" "+_vm._s(_vm.sharedAlertMessage)+" ")])],1)],1):_vm._e(),(_vm.isDir && _vm.internalLoaded && _vm.node !== null)?_c('b-row',{staticClass:"mt-1 mb-1 ml-2 mr-0 p-0 text-left"},[_c('b-col',{class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light',style:({
                'font-weight': _vm.isDir ? '500' : '300',
            })},[_c('b-button',{attrs:{"title":(_vm.internalLoaded ? _vm.internalNode : _vm.node).path,"size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'white',"disabled":!_vm.select ||
                    (_vm.select !== 'directory' && _vm.select !== 'files') ||
                    _vm.isUserHome((_vm.internalLoaded ? _vm.internalNode : _vm.node).path)},on:{"click":function($event){return _vm.selectNode(
                        _vm.internalLoaded ? _vm.internalNode : _vm.node,
                        'directory'
                    )}}},[_c('i',{staticClass:"fas fa-folder fa-fw mr-2"}),(_vm.sprout)?_c('i',{staticClass:"fas fa-seedling fa-fw mr-2 text-success"}):_vm._e(),_vm._v(" "+_vm._s(_vm.replaceMe( _vm.internalLoaded ? _vm.internalNode.label : _vm.node.label ))+" ")])],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"auto"}},[_c('small',[_vm._v(" "+_vm._s(_vm.isDir ? `${_vm.subDirCount} ${ _vm.subDirCount === 1 ? 'folder' : 'folders' }, ${_vm.fileCount} ${ _vm.fileCount === 1 ? 'file' : 'files' }` : ''))]),(_vm.isShared)?_c('small',[_vm._v(", shared by "+_vm._s(_vm.sharedBy))]):_vm._e()]),(
                _vm.matchingSharingDatasets !== undefined &&
                _vm.matchingSharingDatasets !== null &&
                _vm.matchingSharingDatasets.length > 0
            )?_c('b-col',{staticClass:"mt-1",attrs:{"md":"auto"}},[_c('small',[_vm._v("Shared with "+_vm._s(_vm.matchingSharingDatasets.length)+" user(s)")])]):_vm._e(),_c('b-col',{attrs:{"md":"auto"}},[_c('b-input-group',{attrs:{"size":"sm"}},[(_vm.upload && !_vm.isShared)?_c('b-form-file',{class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light',staticStyle:{"min-width":"15rem"},attrs:{"multiple":"","size":"sm","placeholder":'Upload to \'' +
                        (_vm.internalLoaded ? _vm.internalNode.label : _vm.node.label) +
                        '\'',"drop-placeholder":'Upload to \'' +
                        (_vm.internalLoaded ? _vm.internalNode.label : _vm.node.label) +
                        '\''},model:{value:(_vm.filesToUpload),callback:function ($$v) {_vm.filesToUpload=$$v},expression:"filesToUpload"}}):_vm._e(),(_vm.create)?_c('span',{staticClass:"ml-2"},[(!_vm.isShared)?_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"disabled":_vm.creatingDirectory,"size":"sm","title":"Create Folder","variant":_vm.profile.darkMode
                                ? 'outline-light'
                                : 'outline-dark'},on:{"click":_vm.showCreateDirectoryModal}},[_c('i',{staticClass:"fas fa-folder-plus fa-fw"})]):_vm._e(),_c('b-modal',{attrs:{"title-class":_vm.profile.darkMode ? 'text-white' : 'text-dark',"title":"Bind Project","id":'bindProject' +
                            (_vm.internalLoaded
                                ? _vm.internalNode.label
                                : _vm.node.label),"centered":"","header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"body-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"close":"","ok-disabled":_vm.selectedProject === null},on:{"ok":function($event){return _vm.bindProject(
                                _vm.internalLoaded
                                    ? _vm.internalNode.path
                                    : _vm.node.path
                            )}}},[_c('b-row',[_c('b-col',[_c('b',{class:_vm.profile.darkMode
                                            ? 'text-white'
                                            : 'text-dark'},[_vm._v(" Select the MIAPPE project and study this task corresponds to. ")])])],1),(_vm.userProjects.length > 0)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{class:_vm.profile.darkMode
                                        ? 'text-light'
                                        : 'text-dark',attrs:{"cols":"3"}},[_c('i',[_vm._v("Project")])]),(_vm.selectedProject !== null)?_c('b-col',{class:_vm.profile.darkMode
                                        ? 'text-light'
                                        : 'text-dark',attrs:{"cols":"9"}},[_c('i',[_vm._v("Study")])]):_vm._e()],1):_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"3"}},[_c('i',[_vm._v("You haven't started any projects.")])])],1),_vm._l((_vm.userProjects),function(project){return _c('b-row',{key:project.title,staticClass:"mt-1"},[_c('b-col',{staticStyle:{"border-top":"2px solid lightgray"},attrs:{"cols":"3"}},[_c('b-button',{attrs:{"variant":_vm.profile.darkMode
                                            ? 'outline-light'
                                            : 'white'},on:{"click":function($event){_vm.selectedProject = project}}},[_vm._v(_vm._s(project.title)),(
                                            _vm.selectedProject !== null &&
                                            _vm.selectedProject.title ===
                                                project.title
                                        )?_c('i',{staticClass:"fas fa-check fa-fw text-success ml-1"}):_vm._e()])],1),(_vm.selectedProject !== null)?_c('b-col',{staticStyle:{"border-top":"2px solid lightgray","left":"-5px"},attrs:{"cols":"9"}},_vm._l((project.studies),function(study){return _c('b-row',{key:study.title},[_c('b-col',[_c('b-button',{attrs:{"disabled":project.title !==
                                                _vm.selectedProject.title,"variant":_vm.profile.darkMode
                                                    ? 'outline-light'
                                                    : 'white'},on:{"click":function($event){_vm.selectedStudy = study}}},[_vm._v(_vm._s(study.title)),(
                                                    _vm.selectedStudy !==
                                                        null &&
                                                    _vm.selectedStudy.title ===
                                                        study.title &&
                                                    _vm.selectedProject ===
                                                        project
                                                )?_c('i',{staticClass:"fas fa-check fa-fw ml-1 text-success"}):_vm._e()])],1)],1)}),1):_vm._e()],1)})],2),(!_vm.isShared)?_c('b-modal',{attrs:{"title-class":_vm.profile.darkMode ? 'text-white' : 'text-dark',"title":"Create Directory","id":'createDirectoryModal' +
                            (_vm.internalLoaded
                                ? _vm.internalNode.label
                                : _vm.node.label),"centered":"","header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"body-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"close":""},on:{"close":_vm.hideCreateDirectoryModal,"ok":function($event){_vm.createDirectory(
                                (_vm.internalLoaded
                                    ? _vm.internalNode.path
                                    : _vm.node.path) +
                                    '/' +
                                    _vm.newDirectoryName,
                                _vm.profile.djangoProfile.cyverse_token
                            )}}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('span',{class:_vm.profile.darkMode
                                            ? 'text-light'
                                            : 'text-dark'},[_vm._v("Enter a name for this directory.")])]},proxy:true}],null,false,3049219479)},[_c('b-form-input',{class:_vm.profile.darkMode
                                        ? 'input-dark'
                                        : 'input-light',attrs:{"size":"sm","placeholder":'Enter a name'},model:{value:(_vm.newDirectoryName),callback:function ($$v) {_vm.newDirectoryName=$$v},expression:"newDirectoryName"}})],1),(_vm.showingProjectSelection)?_c('div',[_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('b-button',{attrs:{"block":"","variant":_vm.profile.darkMode
                                                ? 'outline-light'
                                                : 'white'},on:{"click":_vm.hideProjectSelection}},[_c('i',{staticClass:"fas fa-times text-danger fa-fw"}),_vm._v(" Hide Project Selection")])],1)],1),_c('b-row',[_c('b-col',[_c('b',{class:_vm.profile.darkMode
                                                ? 'text-white'
                                                : 'text-dark'},[_vm._v(" Select the MIAPPE project and study this task corresponds to. ")])])],1),(_vm.userProjects.length > 0)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{class:_vm.profile.darkMode
                                            ? 'text-light'
                                            : 'text-dark',attrs:{"cols":"3"}},[_c('i',[_vm._v("Project")])]),(_vm.selectedProject !== null)?_c('b-col',{class:_vm.profile.darkMode
                                            ? 'text-light'
                                            : 'text-dark',attrs:{"cols":"9"}},[_c('i',[_vm._v("Study")])]):_vm._e()],1):_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"3"}},[_c('i',[_vm._v("You haven't started any projects.")])])],1),_vm._l((_vm.userProjects),function(project){return _c('b-row',{key:project.title,staticClass:"mt-1"},[_c('b-col',{staticStyle:{"border-top":"2px solid lightgray"},attrs:{"cols":"3"}},[_c('b-button',{attrs:{"variant":_vm.profile.darkMode
                                                ? 'outline-light'
                                                : 'white'},on:{"click":function($event){_vm.selectedProject = project}}},[_vm._v(_vm._s(project.title)),(
                                                _vm.selectedProject !== null &&
                                                _vm.selectedProject.title ===
                                                    project.title
                                            )?_c('i',{staticClass:"fas fa-check fa-fw text-success ml-1"}):_vm._e()])],1),(_vm.selectedProject !== null)?_c('b-col',{staticStyle:{"border-top":"2px solid lightgray","left":"-5px"},attrs:{"cols":"9"}},_vm._l((project.studies),function(study){return _c('b-row',{key:study.title},[_c('b-col',[_c('b-button',{attrs:{"disabled":project.title !==
                                                    _vm.selectedProject.title,"variant":_vm.profile.darkMode
                                                        ? 'outline-light'
                                                        : 'white'},on:{"click":function($event){_vm.selectedStudy = study}}},[_vm._v(_vm._s(study.title)),(
                                                        _vm.selectedStudy !==
                                                            null &&
                                                        _vm.selectedStudy.title ===
                                                            study.title &&
                                                        _vm.selectedProject ===
                                                            project
                                                    )?_c('i',{staticClass:"fas fa-check fa-fw ml-1 text-success"}):_vm._e()])],1)],1)}),1):_vm._e()],1)})],2):_vm._e()],1):_vm._e(),_c('b-modal',{attrs:{"title-class":_vm.profile.darkMode ? 'text-white' : 'text-dark',"title":"Unbind Project","id":'unbindProject' +
                            (_vm.internalLoaded
                                ? _vm.internalNode.label
                                : _vm.node.label),"centered":"","header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"body-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"close":"","ok-variant":"danger"},on:{"ok":function($event){return _vm.unbindProject()}}},[(
                                _vm.projectToUnbind !== null &&
                                _vm.studyToUnbind !== null
                            )?_c('p',{class:_vm.profile.darkMode
                                    ? 'text-light'
                                    : 'text-dark'},[_vm._v(" Project "),_c('b',[_vm._v(_vm._s(_vm.projectToUnbind.title))]),_vm._v(" study "),_c('b',[_vm._v(_vm._s(_vm.studyToUnbind.title))]),_vm._v(" will no longer be bound to folder "),_c('b',[_vm._v(_vm._s(_vm.internalLoaded ? _vm.internalNode.path : _vm.node.path))]),_vm._v(". ")]):_vm._e()])],1):_vm._e(),(_vm.internalLoaded)?_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"title":"Refresh Directory","size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'outline-dark',"disabled":_vm.internalLoading},on:{"click":_vm.refresh}},[(!_vm.internalLoading)?_c('i',{staticClass:"fas fa-sync-alt fa-fw"}):_c('b-spinner',{attrs:{"variant":"secondary","small":"","label":"Loading"}})],1):_vm._e(),(
                        !_vm.internalLoading &&
                        _vm.internalNode.path.split('/').length > 4 &&
                        !_vm.isShared
                    )?_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"title":"Share Directory","size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'outline-dark'},on:{"click":_vm.showShareDirectoryModal}},[_c('i',{staticClass:"fas fa-share-alt fa-fw"})]):_vm._e(),(_vm.isOwned && !_vm.isRoot)?_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"size":"sm","disabled":_vm.deletingDirectory,"title":"Delete Folder","variant":_vm.profile.darkMode ? 'outline-light' : 'outline-dark'},on:{"click":function($event){return _vm.deleteFolder(
                            _vm.internalLoaded ? _vm.internalNode.path : _vm.node.path,
                            _vm.profile.djangoProfile.cyverse_token
                        )}}},[_c('i',{staticClass:"fas fa-trash text-danger fa-fw"})]):_vm._e(),(!_vm.isShared)?_c('b-modal',{attrs:{"title-class":_vm.profile.darkMode ? 'text-white' : 'text-dark',"title":'Share ' +
                        (_vm.internalLoaded ? _vm.internalNode.path : _vm.node.path),"size":"lg","centered":"","header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"body-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"id":'shareDirectoryModal' +
                        (_vm.internalLoaded ? _vm.internalNode.label : _vm.node.label)},on:{"ok":_vm.shareDataset,"close":_vm.hideShareDirectoryModal}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{class:_vm.profile.darkMode
                                        ? 'text-white'
                                        : 'text-dark'},[_c('small',[_vm._v("Choose who to share this folder with.")])]),_c('b-col',{attrs:{"md":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-right",attrs:{"disabled":_vm.usersLoading,"variant":_vm.profile.darkMode
                                            ? 'outline-light'
                                            : 'white',"size":"sm","title":"Rescan users"},on:{"click":_vm.refreshUsers}},[(_vm.usersLoading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Rescanning...","variant":_vm.profile.darkMode
                                                ? 'light'
                                                : 'dark'}}):_c('i',{staticClass:"fas fa-redo mr-1"}),_vm._v("Rescan Users")],1)],1)],1),(_vm.usersLoading)?_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"align-self":"end"}},[_c('b-spinner',{attrs:{"label":"Loading...","variant":"secondary","small":""}})],1)],1):(_vm.sharingUsers.length > 0)?_c('b-row',{attrs:{"align-v":"center","align-h":"center"}},[_c('b-col',[_c('b-form',[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-checkbox-group',{attrs:{"stacked":"","aria-describedby":ariaDescribedby,"buttons":"","variant":_vm.profile.darkMode
                                                    ? 'outline-light'
                                                    : 'outline-dark',"size":"lg"},model:{value:(_vm.sharedUsers),callback:function ($$v) {_vm.sharedUsers=$$v},expression:"sharedUsers"}},_vm._l((_vm.sharingUsers),function(user){return _c('b-form-checkbox',{key:user.username,attrs:{"size":"sm","button":"","button-variant":_vm.profile.darkMode
                                                        ? 'outline-light'
                                                        : 'outline-dark',"value":user.username},model:{value:(_vm.sharedUsers),callback:function ($$v) {_vm.sharedUsers=$$v},expression:"sharedUsers"}},[(
                                                        user.github_profile
                                                    )?_c('b-img',{staticClass:"avatar m-0 mb-1 mr-2 p-0 github-hover logo",staticStyle:{"width":"2rem","height":"2rem","left":"-3px","top":"1.5px","border":"1px solid"},attrs:{"rounded":"circle","src":user.github_profile
                                                            ? user
                                                                  .github_profile
                                                                  .avatar_url
                                                            : ''}}):_c('i',{staticClass:"far fa-user fa-fw mr-2 ml-2"}),_c('b',[_vm._v(_vm._s(user.first_name)+" "+_vm._s(user.last_name))]),_vm._v(" ("+_vm._s(user.username)+") "),(
                                                        _vm.sharedUsers.includes(
                                                            user.username
                                                        )
                                                    )?_c('i',{staticClass:"fas fa-check"}):_vm._e()],1)}),1)]}}],null,false,4220806517)})],1)],1)],1):_c('b-row',[_c('b-col')],1)],1)],1):_vm._e(),(!_vm.isOpen)?_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"title":"Expand Directory","size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'outline-dark'},on:{"click":_vm.open}},[_c('i',{staticClass:"fas fa-caret-up fa-fw"})]):_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"title":"Collapse Directory","size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'outline-dark',"disabled":_vm.deleting},on:{"click":_vm.toggle}},[_c('i',{staticClass:"fas fa-caret-down fa-fw"})])],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"p-0"},[_c('b-col',[(
                    _vm.upload &&
                    !_vm.isShared &&
                    (_vm.filesToUpload.length > 0 || _vm.uploadingFiles.length > 0)
                )?_c('b-card',{attrs:{"no-body":"","bg-variant":_vm.profile.darkMode ? 'dark' : 'light'}},[_c('b-row',[_c('b-col',[_c('b-list-group',_vm._l((_vm.filesToUpload),function(file){return _c('b-list-group-item',{key:file.name,class:_vm.profile.darkMode ? 'darkitems' : ''},[_c('span',[_c('i',{staticClass:"fas fa-upload fa-fw"}),_vm._v(" "+_vm._s(file.name))]),(
                                        _vm.uploadedFiles.some(
                                            (f) => f.name === file.name
                                        )
                                    )?_c('span',[_c('i',{staticClass:"fas fa-check text-success ml-1"})]):(_vm.uploading)?_c('span',[_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Uploading...","variant":"secondary","small":""}})],1):_vm._e()])}),1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{attrs:{"block":"","size":"sm","disabled":_vm.filesToUpload.length === 0 || _vm.uploading,"variant":_vm.profile.darkMode
                                    ? 'outline-light'
                                    : 'outline-dark'},on:{"click":function($event){return _vm.uploadFiles(
                                    _vm.filesToUpload,
                                    _vm.internalLoaded
                                        ? _vm.internalNode.path
                                        : _vm.node.path,
                                    _vm.profile.djangoProfile.cyverse_token
                                )}}},[(_vm.uploading)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Uploading...","variant":"secondary","small":""}}):_c('i',{staticClass:"fas fa-angle-right fa-fw"}),_vm._v(" Upload")],1)],1)],1)],1):_vm._e(),(!_vm.isShared && _vm.deletingPaths.length > 0)?_c('b-card',{attrs:{"no-body":"","bg-variant":_vm.profile.darkMode ? 'dark' : 'light'}},[_c('b-row',[_c('b-col',_vm._l((_vm.deletingPaths),function(path){return _c('b-list-group-item',{key:path,class:_vm.profile.darkMode ? 'darkitems' : 'lightitems',attrs:{"variant":_vm.profile.darkMode ? 'dark' : 'light'}},[_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-trash fa-fw"}),_vm._v(" Deleting "+_vm._s(path))]),_c('span',[_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Uploading...","variant":"danger","small":""}})],1)])}),1)],1)],1):_vm._e()],1)],1),_c('b-row',{staticClass:"text-center text-secondary",attrs:{"align-h":"center","align-v":"center"}},[(_vm.creatingDirectory)?_c('b-col',[_c('small',[_vm._v("Creating folder...")]),_c('b-spinner',{staticClass:"ml-1",attrs:{"variant":"secondary","small":""}})],1):_vm._e()],1),(_vm.isDir && !_vm.internalLoaded)?_c('b-row',{staticClass:"mt-0 mb-0 ml-2 mr-0 p-0"},[_c('b-col',{class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light'},[_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'white',"disabled":!_vm.select ||
                    (_vm.select !== 'directory' && _vm.select !== 'files')},on:{"click":function($event){return _vm.selectNode(
                        _vm.internalLoaded ? _vm.internalNode : _vm.node,
                        'directory'
                    )}}},[_c('i',{staticClass:"fas fa-folder fa-fw mr-2"}),(_vm.sprout)?_c('i',{staticClass:"fas fa-seedling fa-fw mr-2 text-success"}):_vm._e(),_vm._v(_vm._s(_vm.replaceMe( _vm.internalLoaded ? _vm.internalNode.label : _vm.node.label )))])],1),_c('b-col',{class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light',attrs:{"md":"auto"}},[(
                    _vm.internalLoaded &&
                    (_vm.internalLoaded ? _vm.internalNode.path : _vm.node.path).split(
                        '/'
                    ).length > 4 &&
                    !_vm.isShared
                )?_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"size":"sm","disabled":_vm.deletingDirectory,"title":"Delete Directory","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteFolder(
                        _vm.internalLoaded ? _vm.internalNode.path : _vm.node.path,
                        _vm.profile.djangoProfile.cyverse_token
                    )}}},[_c('i',{staticClass:"fas fa-trash fa-fw"})]):_vm._e(),_c('b-button',{staticClass:"ml-1 mr-1",attrs:{"title":"Expand Directory","size":"sm","variant":_vm.profile.darkMode ? 'outline-light' : 'white'},on:{"click":function($event){return _vm.loadDirectory(
                        _vm.internalLoaded ? _vm.internalNode.path : _vm.node.path,
                        _vm.profile.djangoProfile.cyverse_token
                    )}}},[(!_vm.internalLoaded && !_vm.internalLoading)?_c('i',{staticClass:"fas fa-caret-up fa-fw"}):(!_vm.internalLoaded && _vm.internalLoading)?_c('b-spinner',{attrs:{"small":"","variant":"secondary","type":"grow","label":"Loading"}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.isDir && _vm.internalLoaded)?_c('b-list-group-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"mt-2 mb-1 ml-2 mr-0 p-0",staticStyle:{"background-color":"transparent"},attrs:{"variant":_vm.profile.darkMode ? 'outline-light' : 'outline-dark'}},[_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.numFiles > _vm.filePageSize),expression:"numFiles > filePageSize"}]},[_c('b-col',{staticClass:"ml-2",attrs:{"md":"auto","align-self":"center"}},[_c('b-pagination',{staticClass:"mt-3",attrs:{"pills":"","size":"md","total-rows":_vm.numFiles,"per-page":_vm.filePageSize,"aria-controls":"files"},scopedSlots:_vm._u([{key:"page",fn:function({ page, active }){return [(active)?_c('b',[_vm._v(_vm._s(page))]):_c('i',[_vm._v(_vm._s(page))])]}}],null,false,1479778492),model:{value:(_vm.filePage),callback:function ($$v) {_vm.filePage=$$v},expression:"filePage"}})],1)],1),_c('b-overlay',{attrs:{"show":_vm.downloading,"variant":_vm.profile.darkMode ? 'dark' : 'light',"rounded":"sm"}},_vm._l((_vm.filteredFiles),function(child,index){return _c('b-row',{key:index,staticClass:"mt-1 mb-1 ml-2 mr-0 p-0",class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light',staticStyle:{"border-top":"1px solid rgba(211, 211, 211, 0.5)"},attrs:{"id":"files"}},[_c('b-col',[_c('b-button',{staticClass:"mt-1 mb-1 ml-4",attrs:{"disabled":!_vm.select || _vm.select !== 'file',"size":"sm","variant":_vm.profile.darkMode
                                ? 'outline-light'
                                : 'outline-dark'},on:{"click":function($event){return _vm.selectNode(child, 'file')}}},[_c('i',{staticClass:"fas fa-file fa-fw"}),_vm._v(" "+_vm._s(child.label))])],1),_c('b-col',{attrs:{"align-self":"center"}},[_c('small',[_vm._v(_vm._s(_vm.formatBytes(child['file-size'])))])]),_c('b-col',{attrs:{"md":"auto","align-self":"center"}},[(_vm.previewsLoaded && _vm.fileIsImage(child.label))?_c('b-img-lazy',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"src":_vm.getFileURL(child)}}):_vm._e()],1),_c('b-col',{attrs:{"md":"auto","align-self":"center"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"m-1",attrs:{"id":"popover-reactive-1","disabled":!_vm.fileIsImage(child.label),"variant":_vm.profile.darkMode ? 'outline-light' : 'white',"size":"sm","title":'View ' + child.label},on:{"click":function($event){return _vm.viewFile(child)}}},[_c('i',{staticClass:"fas fa-eye fa-fw"})]),_c('b-button',{staticClass:"m-1",attrs:{"size":"sm","title":"Download File","disabled":_vm.downloading,"variant":_vm.profile.darkMode
                                ? 'outline-light'
                                : 'outline-dark'},on:{"click":function($event){return _vm.downloadFile(
                                child.path,
                                _vm.profile.djangoProfile.cyverse_token
                            )}}},[_c('i',{staticClass:"fas fa-download fa-fw"})]),(_vm.isOwned)?_c('b-button',{staticClass:"m-1",attrs:{"size":"sm","title":"Delete File","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteFile(
                                child.path,
                                _vm.profile.djangoProfile.cyverse_token
                            )}}},[_c('i',{staticClass:"fas fa-trash fa-fw"})]):_vm._e()],1)],1)}),1)],1):_vm._e(),_vm._l((_vm.internalLoaded
            ? _vm.internalLoadedFolders
            : _vm.node.folders),function(child,index){return _c('b-list-group-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],key:child.path,staticClass:"mt-2 mb-1 ml-2 mr-0 p-0",staticStyle:{"background-color":"transparent"},attrs:{"variant":_vm.profile.darkMode ? 'outline-light' : 'outline'}},[_c('data-tree',{key:index,ref:"childNode",refInFor:true,staticClass:"pt-1 pb-1 mb-0 ml-2 mr-0 p-0",staticStyle:{"border-top":"1px solid rgba(211, 211, 211, 0.5)","border-left":"2px solid rgba(211, 211, 211, 0.5)"},attrs:{"select":_vm.select,"upload":_vm.upload,"download":_vm.download,"create":_vm.create,"agents":_vm.agents,"search":_vm.search,"node":child},on:{"selectPath":function($event){return _vm.selectNode(child, 'directory')},"deleted":function($event){return _vm.waitForDeletion(child.path)}}})],1)}),(
            _vm.internalLoaded && _vm.internalNode !== null && _vm.selectedFile !== null
        )?_c('b-modal',{staticClass:"overflow-hidden",attrs:{"ok-only":"","body-bg-variant":_vm.profile.darkMode ? 'dark' : 'light',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'light',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'light',"title-class":_vm.profile.darkMode ? 'text-white' : 'text-dark',"header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"body-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"ok-variant":"secondary","ok-title":"Close","size":"xl","centered":"","title":_vm.selectedFileTitle,"id":'selectedFile' + _vm.internalNode.path},on:{"close":_vm.onSelectedFileClosed}},[(!_vm.previewsLoaded)?_c('b-spinner',{attrs:{"type":"grow","label":"Loading...","variant":"secondary"}}):_vm._e(),(_vm.fileIsImage(_vm.selectedFile.label))?_c('b-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.previewsLoaded),expression:"previewsLoaded"}],staticStyle:{"width":"68rem"},attrs:{"center":"","src":_vm.fileURLs[_vm.selectedFile.path]}}):(_vm.fileIsText(_vm.selectedFile.label))?_c('b-embed',{attrs:{"type":"iframe","src":_vm.fileURLs[_vm.selectedFile.path]}}):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }