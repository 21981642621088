var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"vl",attrs:{"fluid":""}},[(!_vm.authorized)?_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_c('p',{class:_vm.profile.darkMode ? 'text-white' : 'text-dark'},[_c('i',{staticClass:"fas fa-exclamation-circle fa-3x fa-fw"}),_c('br'),_c('br'),_vm._v(" You do not have access to this agent. "),_c('br')])])],1):_c('div',[_c('b-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[(_vm.alertEnabled)?_c('b-col',[_c('b-alert',{attrs:{"show":_vm.alertEnabled,"variant":_vm.alertMessage.startsWith('Failed')
                                ? 'danger'
                                : 'success',"dismissible":""},on:{"dismissed":function($event){_vm.alertEnabled = false}}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1):_vm._e()],1),(_vm.agentLoading)?_c('b-row',[_c('b-col',[_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode ? 'light' : 'dark'}}),_c('span',{class:_vm.profile.darkMode ? 'text-white' : 'text-dark'},[_vm._v("Loading agent...")])],1)],1):_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"border-variant":"secondary","header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"text-variant":_vm.profile.darkMode ? 'white' : 'dark'}},[_c('div',{class:_vm.profile.darkMode
                                    ? 'theme-dark'
                                    : 'theme-light'},[(_vm.getAgent.logo)?_c('b-img',{staticClass:"card-img-right overflow-hidden",staticStyle:{"max-height":"3rem","position":"absolute","right":"20px","top":"20px","z-index":"1"},attrs:{"rounded":"","right":"","src":_vm.getAgent.logo}}):_vm._e(),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"auto"}},[_c('h4',{class:_vm.profile.darkMode
                                                        ? 'text-white'
                                                        : 'text-dark'},[_vm._v(" "+_vm._s(_vm.getAgent.name)+" "),_c('small',[(
                                                            _vm.getAgent.is_healthy
                                                        )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],staticClass:"fas fa-heartbeat text-success fa-fw",attrs:{"title":"Healthy"}}):_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],staticClass:"fas fa-heart-broken text-danger fa-fw",attrs:{"title":"Unhealthy"}}),(_vm.agent.public)?_c('i',{staticClass:"fas fa-unlock-alt text-success fa-fw",attrs:{"title":"Public"}}):_c('i',{staticClass:"fas fa-lock text-warning fa-fw",attrs:{"title":"Protected"}}),(
                                                            _vm.getAgent.disabled
                                                        )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],staticClass:"fas fa-exclamation-circle text-secondary fa-fw",attrs:{"title":"Disabled"}}):_vm._e()])])])],1),_c('b-row',[_c('b-col',[_c('small',[_vm._v(_vm._s(_vm.getAgent.description))])])],1),(
                                            !_vm.getAgent.is_healthy &&
                                            _vm.healthcheckOutput.length > 0
                                        )?_c('div',[_c('br'),_c('b-row',{class:_vm.profile.darkMode
                                                    ? 'theme-container-dark m-0 p-1'
                                                    : 'theme-container-light m-0 p-1'},[_c('b-col',{staticClass:"m-0 p-0 pl-3 pr-3 pt-1 text-danger",staticStyle:{"white-space":"pre-line"}},_vm._l((_vm.healthcheckOutput),function(line){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                                        line !==
                                                            undefined &&
                                                        line !== null
                                                    ),expression:"\n                                                        line !==\n                                                            undefined &&\n                                                        line !== null\n                                                    "}],key:line},[_vm._v(_vm._s(line + '\n'))])}),0)],1)],1):_vm._e(),_c('br'),_c('b-row',[_c('b-col',[_c('h5',{class:_vm.profile.darkMode
                                                        ? 'text-white'
                                                        : 'text-dark'},[_vm._v(" Configuration ")]),_c('b-row',[_c('b-col',[_c('small',[_vm._v("scheduler")]),_c('br'),_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.getAgent.executor)+" ")])])],1),_c('b-row',[_c('b-col',[_c('small',[_vm._v("working directory")]),_c('br'),_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.getAgent.workdir)+" ")])])],1),_c('b-row',[_c('b-col',[_c('small',[_vm._v("setup commands")]),_c('br'),_c('b',{staticClass:"ml-3"},[_c('code',[_vm._v(_vm._s(_vm.getAgent.pre_commands))])])])],1)],1)],1),_c('br'),_c('b-row',[_c('b-col',[_c('h5',{class:_vm.profile.darkMode
                                                        ? 'text-white'
                                                        : 'text-dark'},[_vm._v(" Resources "),_c('small',[_vm._v("(per container)")])]),_c('b',[_vm._v(_vm._s(_vm.getAgent.max_cores))]),_c('small',[_vm._v(" core(s)")]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getAgent.max_processes))]),_c('small',[_vm._v(" process(es)")]),_c('br'),(
                                                    parseInt(
                                                        _vm.getAgent.max_mem
                                                    ) < 0
                                                )?_c('span',[_c('small',[_vm._v("Virtual memory")])]):(
                                                    parseInt(
                                                        _vm.getAgent.max_mem
                                                    ) > 0
                                                )?_c('span',[_c('b',[_vm._v(_vm._s(_vm.getAgent.max_mem))]),_c('small',[_vm._v(" GB memory")])]):(
                                                    parseInt(
                                                        _vm.getAgent.max_mem
                                                    ) === -1
                                                )?_c('span',[_c('small',[_vm._v("virtual memory")])]):_vm._e(),_c('br'),(_vm.getAgent.gpu)?_c('span',[_c('i',{staticClass:"far fa-check-circle",class:_vm.getAgent.gpu
                                                            ? 'text-warning'
                                                            : ''}),_c('small',[_vm._v("GPU")])]):_c('span',[_c('small',[_vm._v(" No GPU ")])])])],1),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"auto"}},[_c('h5',{class:_vm.profile.darkMode
                                                        ? 'text-light'
                                                        : 'text-dark'},[_vm._v(" Connection Status ")])]),_c('b-col'),_c('b-col',{staticClass:"ml-0",attrs:{"md":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-0",attrs:{"variant":_vm.profile.darkMode
                                                        ? 'outline-light'
                                                        : 'white',"size":"sm","disabled":_vm.checkingConnection,"title":'Verify connection to ' +
                                                    _vm.getAgent.name},on:{"click":_vm.checkConnection}},[(
                                                        _vm.checkingConnection
                                                    )?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode
                                                            ? 'light'
                                                            : 'dark'}}):_c('i',{staticClass:"fas fa-wave-square fa-fw"}),_vm._v(" Check")],1)],1)],1)],1)],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }