var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"mt-2 pt-1 overflow-hidden",attrs:{"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"border-variant":"secondary","text-variant":_vm.profile.darkMode ? 'white' : 'dark',"body-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"no-body":""}},[_c('b-card-body',[_c('b-link',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark',attrs:{"to":{
                name: 'task',
                params: {
                    owner: _vm.task.owner,
                    guid: _vm.task.guid,
                },
            },"replace":""}},[_vm._v(_vm._s(_vm.task.guid))]),_c('br'),(!_vm.task.is_complete)?_c('span',[_c('b-spinner',{staticClass:"mb-1 mr-1",staticStyle:{"width":"0.7rem","height":"0.7rem"},attrs:{"variant":_vm.profile.darkMode ? 'text-white' : 'text-dark'}})],1):_vm._e(),_c('b',{class:_vm.task.is_failure
                    ? 'text-danger'
                    : _vm.task.is_cancelled || _vm.task.is_timeout
                    ? 'text-secondary'
                    : _vm.task.is_complete
                    ? 'text-success'
                    : _vm.profile.darkMode
                    ? 'text-white'
                    : 'text-dark'},[_vm._v(_vm._s(_vm.getTaskStatus))]),_c('small',{staticClass:"ml-1 mr-1"},[_vm._v("on")]),_c('small',[_c('b-link',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark',attrs:{"to":{
                    name: 'agent',
                    params: {
                        name: _vm.task.agent.name,
                    },
                }}},[(_vm.task.agent.logo)?_c('b-img',{staticClass:"overflow-hidden",staticStyle:{"max-height":"1rem"},attrs:{"rounded":"","src":_vm.task.agent.logo}}):_c('i',{staticClass:"fas fa-server fa-fw"}),_vm._v(" "+_vm._s(_vm.task.agent ? _vm.task.agent.name : '[agent removed]'))],1),_vm._v(" "+_vm._s(_vm.prettify(_vm.task.updated)))],1),(_vm.project && _vm.task.project !== null)?_c('span',[_c('br'),_c('b-link',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark',attrs:{"to":{
                    name: 'project',
                    params: {
                        owner: _vm.task.project.owner,
                        title: _vm.task.project.title,
                    },
                }}},[_c('b-img',{staticClass:"mb-1 mr-1",staticStyle:{"max-width":"18px"},attrs:{"src":_vm.profile.darkMode
                            ? require('../../assets/miappe_icon.png')
                            : require('../../assets/miappe_icon_black.png')}}),(_vm.task.project !== null)?_c('span',[_vm._v(_vm._s(_vm.task.project.title)+" "),(_vm.task.study !== null)?_c('small',[_vm._v(_vm._s(_vm.task.study.title))]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(_vm.task.tags !== undefined && _vm.task.tags.length > 0)?_c('span',_vm._l((_vm.task.tags),function(tag){return _c('b-badge',{key:tag,staticClass:"ml-2 mb-2",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(tag)+" ")])}),1):_vm._e(),_c('br'),(
                _vm.task.workflow_image_url !== undefined &&
                _vm.task.workflow_image_url !== null
            )?_c('b-img',{staticClass:"card-img-right",staticStyle:{"max-width":"5rem","position":"absolute","right":"-15px","top":"-20px","z-index":"1"},attrs:{"rounded":"","right":"","src":_vm.task.workflow_image_url}}):_vm._e(),(_vm.task.workflow_name !== null)?_c('small',{staticClass:"mr-1 mb-0"},[_c('b-link',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark',on:{"click":function($event){return _vm.openInNewTab(
                        'https://github.com/' +
                            _vm.task.workflow_owner +
                            '/' +
                            _vm.task.workflow_name
                    )}}},[_c('i',{staticClass:"fab fa-github fa-fw"}),_vm._v(" "+_vm._s(_vm.task.workflow_owner)+"/"+_vm._s(_vm.task.workflow_name))])],1):_vm._e(),_c('br'),(_vm.task.output_path !== null && _vm.task.output_path !== '')?_c('span',[(_vm.task.input_path !== null)?_c('small',[_c('i',{staticClass:"far fa-folder fa-fw mr-1"}),_vm._v(_vm._s(_vm.task.input_path))]):_c('small',[(_vm.profile.darkMode)?_c('i',{staticClass:"far fa-circle text-white fa-fw"}):_c('i',{staticClass:"far fa-circle text-dark fa-fw"})]),_c('small',[(_vm.profile.darkMode)?_c('i',{staticClass:"fas fa-arrow-right text-white fa-fw mr-1 ml-1"}):_c('i',{staticClass:"fas fa-arrow-right text-dark fa-fw mr-1 ml-1"})]),(_vm.task.output_path !== null)?_c('small',[_c('i',{staticClass:"far fa-folder fa-fw mr-1"}),_vm._v(_vm._s(_vm.task.output_path))]):_vm._e()]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }