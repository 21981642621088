var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"p-2 vl",attrs:{"fluid":""}},[(_vm.tasksLoading)?_c('b-row',[_c('b-col',[_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode ? 'light' : 'dark'}}),_c('span',{class:_vm.profile.darkMode ? 'text-white' : 'text-dark'},[_vm._v("Loading task...")])],1)],1):(_vm.notFound)?_c('b-row',{attrs:{"align-content":"center"}},[_c('b-col',{staticClass:"text-center"},[_c('p',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-circle fa-3x fa-fw"}),_c('br'),_c('br'),_vm._v(" This task does not exist. ")])])],1):_c('b-row',{staticClass:"m-0 p-0"},[_c('b-col',{staticClass:"m-0 p-0",attrs:{"md":"auto","align-self":"end"}},[_c('h4',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark'},[_c('i',{staticClass:"fas fa-tasks fa-fw"}),_vm._v(" "+_vm._s(_vm.getTask.guid)+" ")])]),_c('b-col',{staticClass:"m-0 ml-2 p-0"},[_c('h5',_vm._l((_vm.getTask.tags),function(tag){return _c('b-badge',{key:tag,staticClass:"mr-2",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(tag))])}),1)]),_c('b-col',{attrs:{"md":"auto"}},[(!_vm.getTask.is_complete)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","variant":_vm.profile.darkMode ? 'text-white' : 'text-dark'}}):_vm._e(),_c('b',{class:_vm.getTask.is_failure || _vm.getTask.is_timeout
                            ? 'text-danger'
                            : _vm.getTask.is_cancelled
                            ? 'text-secondary'
                            : _vm.getTask.is_complete
                            ? 'text-success'
                            : _vm.profile.darkMode
                            ? 'text-white'
                            : 'text-dark'},[_vm._v(_vm._s(_vm.getTaskStatus))]),_c('small',{staticClass:"ml-1 mr-1"},[_vm._v("on")]),_c('b-link',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark',attrs:{"to":{
                        name: 'agent',
                        params: {
                            name: _vm.getTask.agent.name,
                        },
                    }}},[(_vm.getTask.agent.logo)?_c('b-img',{staticClass:"overflow-hidden",staticStyle:{"max-height":"1rem","top":"-5px"},attrs:{"rounded":"","src":_vm.getTask.agent.logo}}):_c('i',{staticClass:"fas fa-server fa-fw"}),_vm._v(" "+_vm._s(_vm.getTask.agent ? _vm.getTask.agent.name : '[agent removed]'))],1)],1)],1),_c('b-row',[_c('b-col',[(
                        _vm.getTask.output_path !== null &&
                        _vm.getTask.output_path !== ''
                    )?_c('span',[(_vm.getTask.input_path !== null)?_c('small',[_c('i',{staticClass:"far fa-folder fa-fw mr-1"}),_vm._v(_vm._s(_vm.getTask.input_path))]):_c('small',[(_vm.profile.darkMode)?_c('i',{staticClass:"far fa-circle text-white fa-fw"}):_c('i',{staticClass:"far fa-circle text-dark fa-fw"})]),_c('small',[(_vm.profile.darkMode)?_c('i',{staticClass:"fas fa-arrow-right text-white fa-fw mr-1 ml-1"}):_c('i',{staticClass:"fas fa-arrow-right text-dark fa-fw mr-1 ml-1"})]),(_vm.getTask.output_path !== null)?_c('small',[_c('i',{staticClass:"far fa-folder fa-fw mr-1"}),_vm._v(_vm._s(_vm.getTask.output_path))]):_vm._e()]):_vm._e()])],1),_c('b-row',{staticClass:"m-0 p-0"},[_c('b-col',{staticClass:"m-0 p-0",attrs:{"align-self":"end"}},[_c('h5',{class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light'},[(_vm.getTask.project !== null)?_c('b-link',{class:_vm.profile.darkMode
                                ? 'text-light ml-3'
                                : 'text-dark ml-3',attrs:{"to":{
                            name: 'project',
                            params: {
                                owner: _vm.getTask.project.owner,
                                title: _vm.getTask.project.title,
                            },
                        }}},[_c('b-img',{staticClass:"mb-1 mr-1",staticStyle:{"max-width":"18px"},attrs:{"src":_vm.profile.darkMode
                                    ? require('../../assets/miappe_icon.png')
                                    : require('../../assets/miappe_icon_black.png')}}),(_vm.getTask.project !== null)?_c('span',[_vm._v(_vm._s(_vm.getTask.project.title)+" "),(_vm.getTask.study !== null)?_c('small',[_vm._v(_vm._s(_vm.getTask.study.title))]):_vm._e()]):_vm._e()],1):_vm._e()],1)]),_c('b-col',{staticClass:"m-0 mb-2",attrs:{"md":"auto","align-self":"start"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"disabled":_vm.canceling,"variant":_vm.profile.darkMode ? 'outline-light' : 'white',"size":"sm","title":`${_vm.getTask.guid} (click to copy to clipboard)`},on:{"click":_vm.copyGUID}},[_c('i',{staticClass:"fas fa-copy fa-fw"}),_vm._v("GUID")])],1),(
                    _vm.getTask.is_complete &&
                    _vm.getTask.can_restart &&
                    _vm.getWorkflow !== null
                )?_c('b-col',{staticClass:"m-0 mb-2",attrs:{"md":"auto","align-self":"start"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"disabled":_vm.restarted,"variant":_vm.profile.darkMode ? 'outline-light' : 'white',"size":"sm","title":'Restart this task'},on:{"click":_vm.restart}},[_c('i',{staticClass:"fas fa-level-up-alt fa-fw"}),_vm._v("Restart "),(_vm.restarted)?_c('b-spinner',{staticStyle:{"width":"0.7rem","height":"0.7rem"},attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode ? 'light' : 'dark'}}):_vm._e()],1)],1):_vm._e(),(!_vm.getTask.is_complete)?_c('b-col',{staticClass:"m-0 mb-2",attrs:{"md":"auto","align-self":"start"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"disabled":_vm.canceling,"variant":_vm.profile.darkMode ? 'outline-light' : 'white',"size":"sm","title":"Cancel Task"},on:{"click":_vm.cancel}},[_c('i',{staticClass:"fas fa-times fa-fw"}),_vm._v(" Cancel"),(_vm.canceling)?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode ? 'light' : 'dark'}}):_vm._e()],1)],1):_vm._e(),_c('b-col',{staticClass:"m-0 mb-2",attrs:{"md":"auto","align-self":"start"}},[(_vm.getTask.is_complete)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"disabled":_vm.tasksLoading,"variant":_vm.profile.darkMode ? 'outline-light' : 'white',"size":"sm","title":"Refresh Task"},on:{"click":_vm.refresh}},[_c('i',{staticClass:"fas fa-redo"}),_vm._v(" Refresh "),(_vm.tasksLoading)?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode ? 'light' : 'dark'}}):_vm._e()],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"overflow-hidden mt-0",staticStyle:{"min-height":"5rem"},style:(_vm.getTask.is_failure || _vm.getTask.is_timeout
                            ? 'border-bottom: 5px solid red'
                            : _vm.getTask.is_cancelled
                            ? 'border-bottom: 5px solid lightgray'
                            : _vm.getTask.is_complete
                            ? 'border-bottom: 5px solid #d6df5D'
                            : 'border-bottom: 5px solid #e2e3b0'),attrs:{"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"border-variant":"default","footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"no-body":""}},[_c('b-card-body',{staticClass:"mr-1 mt-2 mb-2 ml-2 p-1 pt-2"},[(_vm.getWorkflow !== null)?_c('WorkflowBlurb',{attrs:{"linkable":true,"workflow":_vm.getWorkflow}}):_c('b-row',[_c('b-col',[_c('span',{class:_vm.profile.darkMode
                                            ? 'text-white'
                                            : 'text-dark'},[_c('h5',[_vm._v(_vm._s(_vm.workflowKey))]),_c('br'),_c('i',{staticClass:"fas fa-exclamation-triangle fa-fw"}),_vm._v(" Workflow no longer exists.")])])],1)],1)],1),_c('b-row',{staticClass:"m-0 p-0 mt-1"},[_c('b-col',{staticClass:"m-0 p-0 text-center"},[_c('small',[_c('i',{staticClass:"fas fa-seedling fa-fw"}),_vm._v(" Created "+_vm._s(_vm.prettify(_vm.getTask.created))+" ")])]),(!_vm.getTask.is_complete)?_c('b-col',{staticClass:"m-0 p-0 text-center"},[_c('small',[_c('i',{staticClass:"fas fa-satellite-dish fa-fw"}),_vm._v(" Last updated "+_vm._s(_vm.prettify(_vm.getTask.updated))+" ")])]):_vm._e(),(_vm.getTask.is_complete)?_c('b-col',{staticClass:"m-0 p-0 text-center"},[_c('small',[_c('i',{staticClass:"fas fa-clock fa-fw"}),_vm._v(" Ran for "+_vm._s(_vm.prettifyDuration(_vm.duration(_vm.getTask))))])]):_vm._e(),_c('b-col',{staticClass:"m-0 p-0 text-center"},[(_vm.getTask.is_complete)?_c('small',[_c('i',{staticClass:"fas fa-check fa-fw"}),_vm._v(" Completed "+_vm._s(_vm.prettify(_vm.getTask.completed)))]):_c('small',[_c('i',{staticClass:"fas fa-flag-checkered fa-fw"}),_vm._v(" Due "+_vm._s(_vm.prettify(_vm.getTask.due_time))+" ")])]),(
                            _vm.getTask.is_complete &&
                            _vm.getTask.cleanup_time !== null
                        )?_c('b-col',{staticClass:"m-0 p-0 text-center"},[_c('small',[_c('i',{staticClass:"fas fa-broom fa-fw"}),_vm._v(" Cleaning up "+_vm._s(_vm.prettify(_vm.getTask.cleanup_time)))])]):_vm._e()],1),_c('b-row',{staticClass:"m-0 p-0 mt-2"},[_c('b-col',{staticClass:"m-0 p-1"},[_c('div',{class:_vm.profile.darkMode
                                    ? 'theme-container-dark m-0 p-1 pt-3 pb-3'
                                    : 'theme-container-light m-0 p-1 pt-3 pb-3'},[_c('b-row',{staticClass:"m-0"},[(
                                        _vm.getTask.orchestrator_logs.length > 0
                                    )?_c('b-col',{staticClass:"m-0 p-0 pl-3 pr-3",staticStyle:{"white-space":"pre-line"}},_vm._l((_vm.taskLogs),function(line){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                            line !== undefined &&
                                            line !== null
                                        ),expression:"\n                                            line !== undefined &&\n                                            line !== null\n                                        "}],key:line},[_vm._v(_vm._s(line + '\n'))])}),0):_c('b-col',[_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.getTask.is_complete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-skeleton',{attrs:{"width":"15%"}}),_c('b-skeleton',{attrs:{"width":"25%"}}),_c('b-skeleton',{attrs:{"width":"20%"}})]},proxy:true}])})],1)],1)],1)])],1),(_vm.getTask.is_complete)?_c('b-card',{staticClass:"mt-2",attrs:{"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"border-variant":"default","footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"no-body":""}},[_c('b-card-header',{staticClass:"mt-1",attrs:{"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white'}},[_c('h5',{class:_vm.profile.darkMode
                                    ? 'text-white'
                                    : 'text-dark'},[_c('b-img',{staticClass:"mr-2",staticStyle:{"max-height":"1.7rem"},attrs:{"rounded":"","left":"","src":require('../../assets/logos/cyverse_bright.png')}}),_vm._v(" Data Store ")],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',[_c('datatree',{class:_vm.profile.darkMode
                                            ? 'theme-dark'
                                            : 'theme-light',attrs:{"node":_vm.userDatasets,"upload":true,"download":true,"create":true,"search":_vm.getTask.transfer_path}})],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('b-modal',{staticClass:"overflow-hidden",attrs:{"ok-only":"","body-bg-variant":_vm.profile.darkMode ? 'dark' : 'light',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'light',"footer-bg-variant":_vm.profile.darkMode ? 'dark' : 'light',"title-class":_vm.profile.darkMode ? 'text-white' : 'text-dark',"header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"body-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"footer-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-border-variant":_vm.profile.darkMode ? 'dark' : 'white',"ok-variant":"secondary","ok-title":"Close","size":"xl","centered":"","title":_vm.thumbnailTitle,"id":"thumbnail"},on:{"close":_vm.onThumbnailHidden}},[(_vm.loadingThumbnail)?_c('b-spinner',{attrs:{"type":"grow","label":"Loading...","variant":"warning"}}):_vm._e(),(_vm.fileIsImage(_vm.thumbnailName))?_c('b-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.thumbnailDoneLoading),expression:"thumbnailDoneLoading"}],staticStyle:{"width":"68rem"},attrs:{"center":"","src":_vm.thumbnailUrl},on:{"load":_vm.thumbnailLoaded}}):(_vm.fileIsText(_vm.thumbnailName))?_c('b-embed',{attrs:{"type":"iframe","src":_vm.thumbnailUrl},on:{"load":_vm.thumbnailLoaded}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }