var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 h-100 p-2",style:(_vm.profile.darkMode
            ? 'background-color: #616163'
            : 'background-color: white' + '; min-height: 100%')},[_c('br'),_c('br'),_c('b-container',{staticClass:"p-3 vl",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[(_vm.openedDatasetLoading)?_c('b-row',{attrs:{"align-h":"center"}},[_c('b-spinner',{attrs:{"type":"grow","label":"Loading...","variant":"secondary"}})],1):(_vm.data !== null && _vm.data !== undefined)?_c('b-row',[_c('b-col',[_c('h4',{class:_vm.profile.darkMode
                                    ? 'text-light'
                                    : 'text-dark'},[_vm._v(" "+_vm._s(_vm.openedDataset.path)+" ")]),_c('small',[_vm._v("Open on "),_c('b',[_vm._v(_vm._s(_vm.openedDataset.agent))])]),_c('br'),_c('small',[_vm._v("Showing "),_c('b',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.filesShown)+" of "+_vm._s(_vm.data.files.length))]),_vm._v("file(s), "),_c('b',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.openedDataset.modified.length))]),_vm._v("modified")])])],1):_vm._e()],1),_c('b-col',{attrs:{"md":"auto","align-self":"end"}},[_c('b-row',[_c('b-dropdown',{staticClass:"mb-2 text-right",attrs:{"disabled":!_vm.dataLoading &&
                            _vm.data !== null &&
                            _vm.data.files.length === 0,"dropleft":"","variant":_vm.profile.darkMode ? 'outline-light' : 'white'},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.viewMode)+" View ")]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.setViewMode('Grid')}}},[_vm._v("Grid")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.setViewMode('Carousel')}}},[_vm._v("Carousel")])],1),_c('b-button',{staticClass:"ml-1 mb-2 text-right",attrs:{"title":"Close dataset","variant":"outline-danger"},on:{"click":_vm.closeDataset}},[_vm._v(" Close Dataset "),_c('i',{staticClass:"far fa-folder fa-1x fa-fw"})])],1),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-pagination',{staticClass:"mr-2",attrs:{"size":"sm","per-page":_vm.filesPerPage,"total-rows":_vm.totalFileCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('br'),(!_vm.dataLoading && _vm.data !== null && _vm.data !== undefined)?_c('b-row',{staticClass:"m-1",attrs:{"align-h":"center"}},[_c('b-col',[_c('b-overlay',{attrs:{"variant":_vm.profile.darkMode ? 'dark' : 'light',"show":_vm.openedDataset.opening,"rounded":"sm"}},[(
                            !_vm.dataLoading &&
                            _vm.data !== null &&
                            _vm.data.files.length === 0
                        )?_c('span',[_vm._v("No files in this dataset.")]):(_vm.viewMode === 'Grid')?_c('b-card-group',{attrs:{"columns":""}},_vm._l((_vm.currentPageFiles),function(file){return _c('b-card',{key:file.id,staticClass:"overflow-hidden mb-4 mr-4 text-left",staticStyle:{"min-width":"20rem"},attrs:{"img-src":_vm.openedDataset.opening ||
                                _vm.fileIs3dModel(file.label)
                                    ? require('../../assets/no_preview_thumbnail.png')
                                    : `/apis/v1/datasets/thumbnail/?path=${file.path}`,"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"border-variant":"default","header-border-variant":_vm.profile.darkMode ? 'secondary' : 'default',"text-variant":_vm.profile.darkMode ? 'white' : 'dark'}},[_c('p',{class:_vm.profile.darkMode
                                        ? 'text-light'
                                        : 'text-dark'},[_c('b',[_vm._v(_vm._s(file.label))]),_c('br'),_c('small',[_vm._v(_vm._s(`Last modified: ${_vm.prettifyShort( file['date-modified'] )}`)+" ")])]),_c('hr'),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-left m-0",attrs:{"title":`Download ${file.label}`,"variant":_vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white'},on:{"click":function($event){return _vm.downloadFile(file)}}},[_c('i',{staticClass:"fas fa-download fa-fw"})]),(_vm.fileIsImage(file.label))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-left m-0",attrs:{"title":`Annotate ${file.label}`,"variant":_vm.profile.darkMode
                                        ? 'outline-light'
                                        : 'white'},on:{"click":function($event){return _vm.annotateFile(file)}}},[_c('i',{staticClass:"fas fa-pen-fancy fa-fw"})]):_vm._e()],1)}),1):_vm._e(),_c('b-carousel',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewMode === 'Carousel'),expression:"viewMode === 'Carousel'"}],attrs:{"controls":"","interval":0},on:{"sliding-end":(slide) =>
                                _vm.renderPreview(_vm.currentPageFiles[slide])},model:{value:(_vm.currentCarouselSlide),callback:function ($$v) {_vm.currentCarouselSlide=$$v},expression:"currentCarouselSlide"}},_vm._l((_vm.currentPageFiles),function(file){return _c('b-carousel-slide',{key:file.id,attrs:{"img-src":_vm.fileIsImage(file.label)
                                    ? `/apis/v1/datasets/thumbnail/?path=${file.path}`
                                    : ''},scopedSlots:_vm._u([(
                                    _vm.fileIsText(file.label) ||
                                    _vm.fileIs3dModel(file.label)
                                )?{key:"img",fn:function(){return [_c('div',{class:_vm.profile.darkMode
                                            ? 'theme-container-dark'
                                            : 'theme-container-light',staticStyle:{"min-height":"50rem","white-space":"pre-line"},attrs:{"id":file.id}},[_vm._v(" "+_vm._s(file.textContent)+" ")])]},proxy:true}:(_vm.fileIs3dModel(file.label))?{key:"img",fn:function(){return [_c('div',{class:_vm.profile.darkMode
                                            ? 'theme-container-dark'
                                            : 'theme-container-light',staticStyle:{"min-height":"50rem","white-space":"pre-line"},attrs:{"id":file.id}})]},proxy:true}:null,{key:"default",fn:function(){return [_c('b-row',{class:_vm.profile.darkMode
                                            ? 'theme-container-dark p-3'
                                            : 'theme-container-light p-3',staticStyle:{"opacity":"0.9"}},[_c('b-col',{staticClass:"text-left"},[_c('h5',{class:_vm.profile.darkMode
                                                    ? 'text-light'
                                                    : 'text-dark'},[_vm._v(" "+_vm._s(file.label)+" ")]),_c('small',[_vm._v(_vm._s(`Last modified: ${_vm.prettifyShort( file['date-modified'] )}`))])]),_c('b-col',{attrs:{"md":"auto","align-self":"end"}},[_c('b-button',{staticClass:"text-right m-0",attrs:{"title":`Download ${file.label}`,"variant":_vm.profile.darkMode
                                                    ? 'outline-light'
                                                    : 'white'},on:{"click":function($event){return _vm.downloadFile(file)}}},[_c('i',{staticClass:"fas fa-download fa-fw"})]),(_vm.fileIsImage(file.label))?_c('b-button',{staticClass:"text-right m-0",attrs:{"title":`Annotate ${file.label}`,"variant":_vm.profile.darkMode
                                                    ? 'outline-light'
                                                    : 'white'},on:{"click":function($event){return _vm.annotateFile(file)}}},[_c('i',{staticClass:"fas fa-pen-fancy fa-fw"})]):_vm._e()],1)],1)]},proxy:true}],null,true)})}),1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }