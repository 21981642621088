var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"m-0 p-3",staticStyle:{"background-color":"transparent"},attrs:{"fluid":""}},[_c('div',[(_vm.isRootPath)?_c('div',[_c('b-row',[_c('b-col',[_c('h4',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark'},[_c('i',{staticClass:"fas fa-user-friends fa-fw"}),_vm._v(" Users ")])])],1),_c('b-row',[_c('b-col',[_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"fas fa-search"})])]},proxy:true}],null,false,3231536899)},[_c('b-form-input',{class:_vm.profile.darkMode
                                    ? 'theme-search-dark'
                                    : 'theme-search-light',attrs:{"size":"lg","type":"search"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1),(_vm.usersLoading)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-left"},[(_vm.usersLoading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Loading...","variant":_vm.profile.darkMode ? 'light' : 'dark'}}):_vm._e(),_c('span',{class:_vm.profile.darkMode ? 'text-white' : 'text-dark'},[_vm._v("Loading users...")])],1)],1):_c('b-card-group',{staticClass:"justify-content-center mt-3",attrs:{"deck":"","columns":""}},_vm._l((_vm.filteredUsers),function(user){return _c('b-card',{key:user.username,staticClass:"overflow-hidden mb-4",staticStyle:{"min-width":"30rem","max-width":"40rem"},attrs:{"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"border-variant":"default","header-border-variant":_vm.profile.darkMode ? 'secondary' : 'default',"text-variant":_vm.profile.darkMode ? 'white' : 'dark'}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticStyle:{"color":"white","cursor":"pointer"}},[_c('h5',{class:_vm.profile.darkMode
                                        ? 'text-white'
                                        : 'text-dark'},[(user.github_profile !== undefined)?_c('b-img',{staticClass:"avatar m-0 mb-1 p-0 github-hover logo",staticStyle:{"width":"20px","height":"20px","position":"relative","border":"1px solid #e2e3b0","top":"3px"},attrs:{"rounded":"circle","src":user.github_profile.avatar_url}}):_c('span',{staticStyle:{"max-height":"4rem","max-width":"4rem","opacity":"0.9","position":"absolute","right":"-15px","top":"15px","z-index":"1"}},[_c('i',{staticClass:"far fa-user fa-fw fa-3x"})]),_c('b-link',{class:_vm.profile.darkMode
                                            ? 'text-white ml-2'
                                            : 'text-dark ml-2',attrs:{"href":'https://github.com/' +
                                        user.github_username}},[_c('small',[_vm._v(_vm._s(user.github_username))])]),_c('small',{class:_vm.profile.darkMode
                                            ? 'text-warning'
                                            : 'text-dark'},[_vm._v(" ("+_vm._s(user.username)+")")])],1)]),(
                                user.username ===
                                _vm.profile.djangoProfile.username
                            )?_c('b-col',{staticClass:"m-0 text-left",attrs:{"md":"auto","title":"You"}},[_c('small',[_c('i',{staticClass:"far fa-star text-secondary fa-fw"})])]):_vm._e()],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{class:_vm.profile.darkMode
                                    ? 'text-white'
                                    : 'text-dark'},[_c('b-row',[_c('b-col',[_c('small',[_vm._v("name")])]),_c('b-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(user.first_name)+" "+_vm._s(user.last_name))])],1),_c('b-row',[_c('b-col',[_c('small',[_vm._v("bio")])]),_c('b-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(user.github_profile && user.github_profile.bio ? user.github_profile.bio : '(none)'))])],1)],1)],1)],1)}),1)],1):_c('router-view',{class:_vm.profile.darkMode ? 'theme-dark' : 'theme-light'})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }