var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"p-3 vl",staticStyle:{"background-color":"transparent"},attrs:{"fluid":""}},[(_vm.profileLoading)?_c('div',[_c('br'),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"type":"grow","label":"Loading...","variant":"secondary"}})],1)],1)],1):_c('div',[_c('b-row',[_c('b-col',[_c('h1',{class:_vm.profile.darkMode ? 'text-light' : 'text-dark'},[_vm._v(" Welcome, "+_vm._s(_vm.profile.cyverseProfile.first_name)+" ")])])],1),_c('b-row',{staticClass:"mt-3",attrs:{"align-v":"center"}},[_c('b-col',[_c('b-row',[_c('b-col',[_c('h2',{class:_vm.profile.darkMode
                                    ? 'text-light'
                                    : 'text-dark'},[_vm._v(" Your Profile ")])])],1),_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"start"}},[_c('b-col',{attrs:{"md":"auto"}},[_c('div',[_c('b-row',[(_vm.profile.githubProfile)?_c('b-col',{staticClass:"ml-0 mr-0",attrs:{"md":"auto","align-self":"end"}},[(_vm.profile.githubProfile)?_c('b-img',{staticClass:"avatar",staticStyle:{"max-height":"5rem","max-width":"5rem","position":"relative","top":"20px","box-shadow":"-2px 2px 2px #adb5bd","opacity":"0.9"},attrs:{"rounded":"circle","src":_vm.profile.githubProfile
                                                ? _vm.profile.githubProfile
                                                      .avatar_url
                                                : ''}}):_c('i',{staticClass:"far fa-user fa-fw fa-3x"})],1):_vm._e()],1),_c('br')],1)]),_c('b-col',{staticClass:"ml-0 mr-0",staticStyle:{"color":"white","right":"12px"},attrs:{"align-self":"end"}},[_c('b-row',{class:_vm.profile.darkMode
                                    ? 'text-light'
                                    : 'text-secondary'},[_c('b-col',{staticClass:"ml-0 mr-0",attrs:{"align-self":"end"}},[_c('b-img',{staticClass:"ml-1 mr-1 mb-3",staticStyle:{"max-height":"2rem"},attrs:{"rounded":"","src":require('@/assets/logos/cyverse_logo.png')}}),(_vm.profile.djangoProfile !== null)?_c('b',{class:_vm.profile.darkMode
                                            ? 'text-light'
                                            : 'text-dark'},[_vm._v(" "+_vm._s(_vm.profile.djangoProfile.username)+" ")]):_vm._e(),_c('br'),(_vm.profile.githubProfile)?_c('a',{class:_vm.profile.darkMode
                                            ? 'text-light'
                                            : 'text-dark',attrs:{"href":'https://github.com/' +
                                        _vm.profile.githubProfile.login}},[_c('i',{staticClass:"fab fa-github fa-2x fa-fw"}),_vm._v(" "+_vm._s('https://github.com/' + _vm.profile.githubProfile.login)+" ")]):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"auto"}},[_c('p',[_c('small',[_vm._v("Name")]),_c('br'),_vm._v(" "+_vm._s(_vm.profile.cyverseProfile ? `${_vm.profile.cyverseProfile.first_name} ${_vm.profile.cyverseProfile.last_name} ` : _vm.profile.githubProfile ? _vm.profile.githubProfile.login : '')+" "),_c('br')]),_c('p',[_c('small',[_vm._v("Email Address")]),_c('br'),_vm._v(" "+_vm._s(_vm.profile.cyverseProfile.email)+" (CyVerse) "),_c('br'),_vm._v(" "+_vm._s(_vm.profile.githubProfile ? _vm.profile.githubProfile.email : '')+" (GitHub) ")]),_c('p',[_c('small',[_vm._v("Affiliation")]),_c('br'),_vm._v(" "+_vm._s(_vm.profile.cyverseProfile === undefined ? '' : _vm.profile.cyverseProfile.institution)+" ")]),_c('p',[_c('small',[_vm._v("Bio")]),_c('br'),_vm._v(" "+_vm._s(_vm.profile.githubProfile ? _vm.profile.githubProfile.bio : 'None')+" ")]),_c('p',[_c('small',[_vm._v("Location")]),_c('br'),_vm._v(" "+_vm._s(_vm.profile.githubProfile ? _vm.profile.githubProfile.location : 'None')+" ")])])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }