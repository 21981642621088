var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"title":_vm.project.title,"sub-title":_vm.project.guid,"bg-variant":_vm.profile.darkMode ? 'dark' : 'white',"header-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"text-variant":_vm.profile.darkMode ? 'white' : 'dark',"body-text-variant":_vm.profile.darkMode ? 'white' : 'dark',"no-body":""}},[_c('b-card-body',[_c('h4',[_c('b-link',{class:_vm.profile.darkMode ? 'text-white' : 'text-dark',attrs:{"disabled":!_vm.selectable,"to":{
                    name: 'project',
                    params: {
                        owner: _vm.project.owner,
                        title: _vm.project.title,
                    },
                }}},[_vm._v(_vm._s(_vm.project.title))])],1),(_vm.project.description !== null)?_c('h6',{class:_vm.profile.darkMode ? 'text-white' : 'text-dark'},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]):_vm._e(),(_vm.project.submission_date !== null)?_c('span',[_c('small',[_vm._v("Submission: "+_vm._s(_vm.prettify(_vm.project.submission_date)))]),_c('br')]):_vm._e(),(_vm.project.public_release_date !== null)?_c('span',[_c('small',[_vm._v("Release: "+_vm._s(_vm.prettify(_vm.project.public_release_date)))]),_c('br')]):_vm._e(),(_vm.project.studies.length !== 0)?_c('span',[_c('small',[_vm._v("Studies: "+_vm._s(_vm.project.studies.length))]),_c('br')]):_vm._e(),(_vm.project.team.length !== 0)?_c('span',[_c('small',[_vm._v("Team Members: "+_vm._s(_vm.project.team.length))])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }